.first_wrapper {
    margin-top: -75px;
}

.step1_inputs {
    width: 760px;
    display: flex;
}

.step {
    padding-top: 25px;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

input[type="radio"] {
    height: 20px;
    width: 50px;
    margin-top: -1px;
    vertical-align: middle;
}

.border {
    display: flex;
    width: 80%;
}

.style {
    padding-top: 15px;
}

h6 {
    margin-top: 5px !important;
}
.step3 {
    width: 70vh;
    display: block;
    
}

.radio {
    width: 40px;
}

.row {
    width: 80%;
}

.uploadedPhoto {
    padding-left: 10px;
}

.step2 {
    margin-top: 20px;
}