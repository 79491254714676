img {
    padding: 0 0 0 0;
}
  
  .landscape1 {
    display: block;
    position: relative;
    z-index: 2;
    margin: auto;
  }
  
  .landscape2 {
    position: absolute;
    /* transform: translate(-180px); */
    left: 50%;
    /* margin-top: -252px; */
    z-index: 1;
    text-align: center;
    /* top: -250px; */
    /* left: 129px; */
  }

  .portrait1 {
    display: block;
    position: relative;
    z-index: 2;
    margin: auto;
  }
  
  .portrait2 {
    position: absolute;
    /* transform: translate(-180px); */
    left: 50%;
    /* margin-top: -252px; */
    z-index: 1;
    text-align: center;
    /* top: -250px; */
    /* left: 129px; */
  }


  .mainRunner {
    position: relative;
    margin-top: 80px;
    z-index: 0;
  }


  .landscapet1 {
    z-index: 3;
    position: absolute;
    transform: translate(-50%, -15%);
    top: 312px;
    left: 50%;
    background: rgb(204, 204, 204); /* Fallback for older browsers without RGBA-support */
    background: rgba(204, 204, 204, 0);
  }

  .landscapet2 {
    z-index: 3;
    position: absolute;
    transform: translate(-50%, -15%);
    top: 330px;
    font-size: x-small;
    left: 50%;
    background: rgb(204, 204, 204); /* Fallback for older browsers without RGBA-support */
    background: rgba(204, 204, 204, 0);
  }

  .portraitt1 {
    z-index: 3;
    position: absolute;
    transform: translate(-50%, -15%);
    /* top: 457px; */
    left: 50%;
    background: rgb(204, 204, 204); /* Fallback for older browsers without RGBA-support */
    background: rgba(204, 204, 204, 0);
  }

  .portraitt2 {
    z-index: 3;
    position: absolute;
    transform: translate(-50%, -15%);
    top: 476px;
    font-size: x-small;
    left: 50%;
    background: rgb(204, 204, 204); /* Fallback for older browsers without RGBA-support */
    background: rgba(204, 204, 204, 0);
  }


  .frame {
    box-shadow: 0px 0px 20px 5px gray;
  }

  .step3 {
    z-index: 4;
  }


  h5 {
    margin-top: 40px;
  }