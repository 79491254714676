* {
    box-sizing: border-box;
  }

  .App {
    width: 70%;
    margin: 5rem auto;
    padding: 0px 5px 5px;
    display: flex;
    justify-content: center;
  }

  .Wrapper {
    width: 100%;
  }

  .header {
    background-image: url(./harold.png);
    background-position: top center;
    height: 200px;
    background-size: 1000px;

  }

  .logoWrapper {
    width: 70%;
    display: flex;
    margin: 5rem auto;
    justify-content: center;
    margin-top: -150px;
  }

  .logo {
    width: 230px;
  }
  
  .form-field {
    flex: 2;
  }
  .output {
    margin-left: 3rem;
    flex: 1;
  }

  .form-field label,
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: rgb(84, 84, 84);
  }

  h2 {
    font-size: 25px;
    padding-bottom: 10px;
  }
  
  input {
    height: 35px;
    width: 100%;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
    flex-wrap: wrap; 
  }
  
  input:focus {
    border: 1px solid rgb(0, 208, 255);
  }
  
  .houses {
    display: flex;
    justify-content: space-between;
  }
  .first-division {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0.5rem 0;
    width: 100%;
  }
  button {
    font-weight: 500;
    border-radius: 2px;
    padding: 5px 10px;
  }
  .first-division button {
    align-self: flex-start;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    color: rgb(0, 208, 255);
    border: 1px solid rgb(0, 208, 255);
  }

  .success-message {
    width: 100%;
    display: inline-block;
    padding: 5px;
    background: green;
    color: #fff;
    text-align: center;
    margin: 0.5rem 0;
  }

  .name-parent {
    display: flex;
  }

  .name-child {
    flex: 1;
  }