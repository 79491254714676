h2 {
    margin-top: -75px;
    color: #004a90;
}

.btn-primary {
    background-color: #1D72C3;
}

.btn-primary:hover  {
    background-color: #004a90;
}

.body-wrapper {
    text-align: center;
}


.msg {
    text-align: left;
    flex-wrap: wrap;
    max-width: 800px;
}

.acceptbtn {
    margin-top: 30px;
}