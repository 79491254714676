
.fourth_wrapper {
    text-align: center;
}

.btn-wrapper {
    text-align: center;
}

.create {
    width: 30%;
}

a {
    font-size: small;
    text-align: center;
}

.link-wrapper {
    text-align: center;
}

.btns {
    width: 250px;
    height: 50px
}