.back {
    text-align: left;
    z-index: 4;
}

.traversal_wrapper {
    margin-top: 20px;
    display: flex;
}

.next {
    text-align: left;
    width: 100%;
    z-index: 4;
}

.form-wrapper {
    width: 100%;
}

h5 {
    color: #004a90;
}